import React from 'react';

function RecipeEdit() {
  return (
    <div>
      <h2>레시피 수정 및 추가 페이지</h2>
      {/* 메인 콘텐츠 */}
    </div>
  );
}

export default RecipeEdit;
